@import "../styles/variables.scss";

.new-modal {
    top: 30px !important;
    height: fit-content !important;
    position: relative !important;
    @include breakpoint(mobileonly){
        padding: 0 !important;
        width: 95vw !important;
        max-width: 100vw !important;
        height: 100vh !important;
        bottom: -100px !important;
        // bottom: 0px !important;
        top: 0px !important;
        margin: 0px 0px 0px 0px !important;
        margin: 0 auto;
        position: relative !important;
    }



    @include breakpoint(phablet){
        height: fit-content !important;
    }

    @media screen and (min-width: 600px) and (max-width: 800px)
    and (orientation: landscape) {
        height: 90vh !important;
    }


    .ant-modal-content {
        border-radius: 10px;
        padding: 30px 20px;
        @include breakpoint(mobileonly){
            
            position: absolute !important;
            bottom: 0px !important;
            margin: 0px 0px 0px 0px !important;
            padding: 5px 0px 5px 5px !important;
            padding-bottom: 50px !important;
            margin-bottom: 0px !important;
            width: 95vw !important;
            min-width: none !important;
            height: fit-content !important;
            display: flex !important;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            scrollbar-width: thin !important;
            &::-webkit-scrollbar {
                width: 4px !important;

              }
            &::-webkit-scrollbar-track {
            background: $main-200 !important;
            }
            &::-webkit-scrollbar-thumb {
            background-color: $main-700  !important;
            border-radius: 6px !important;
            border: $main-900 !important;
            }
    
        }

        @media screen and (min-width: 600px) and (max-width: 800px) 
        and (orientation: landscape){
            height: 90vh !important;
        }

        
    }
    .ant-modal-close{
        // width: 100%;
        border-radius: 100%;
        position: absolute;
        top: -70px;
        left: 0;
        right: 0;
        z-index: 10 !important;
        text-align: center;
        margin: 0 auto !important;
        @include rowflex(center,center);

        @include breakpoint(desktop){
            width: fit-content;
            @include rowflexx(flex-start);
        }
    }
    .ant-modal-body{
        width: 100% !important;
        .backcontinue.--bctop{
            margin-top: 0px;
            @include breakpoint(mobileonly){
                margin-top: 80px;
            }
            .backcontinue {

                @include breakpoint(mobileonly){
                    background-color: white;
                    position: absolute ;
                    bottom: 20px ;
                    margin: 0 auto;
                    width: 90% ;
                    height: 120px ;
                    left: 0px ;
                    right: 0px ;
                    button {
                        margin: 0 auto;
                        margin-left: 10px;
                        width: 90% ;
                    }
                }
            }
        }
    }
    .ant-modal-close-x{
        background-color: $main-700 !important;
        border: 2px solid $main-902;
        @include rowflex(center,center);
        width: 50px;
        height: 50px;
        border-radius: 100%;

        span {
            svg {
                color: #fff;
                height: 50px;
            }
        }
    }
}