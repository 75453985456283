/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
@import "../../styles/variables.scss";
*.flickity-slider{
    width: 100% !important;
    // display: flex;
    left: 0px;
    >div{
        margin-right: 20px;
    }
}

.flickity-enabled {
    position: relative;
  }
  
  .flickity-enabled:focus {
    outline: none;
  }
  
  .flickity-viewport {
    // overflow: hidden;
    overflow: visible;
    position: relative;
    height: 100%;
  }
  
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 100px;
  }
  
  /* draggable */
  
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  /* ---- flickity-button ---- */
  
  .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
    // z-index: 100 !important;
  }
  
  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }
  
  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
  }
  
  .flickity-button:active {
    opacity: 0.6;
  }
  
  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }
  
  .flickity-button-icon {
    fill: #333;
  }
  
  /* ---- previous/next buttons ---- */
  
  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  
  .flickity-prev-next-button.previous {
    left: 10px;
  }
  .flickity-prev-next-button.next {
    right: 10px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }


//   .wcarousel-nav *.flickity-viewport{
//     height: fit-content;
//     width: 100%;
//     display: flex !important;
//     flex-direction: row;
//     align-items: flex-start !important;
//     flex-wrap: nowrap !important;
//     padding-left: 0;
//     border-radius: 20px !important;
// }
.wcarousel-nav {
    width: 100%;
    position: relative;
    // @include rowflexx(flex-start);
}
  
// .wcarousel-nav *.flickity-slider{
//     margin-left: 0px;
//     width: 100%;
// }
.wcarousel-nav *.flickity-cell {
   
    counter-increment: carousel-cell;

    @include breakpoint (mobileonly){
        width: 90vw !important;
    }
}

.wcarousel-nav .flickity-cell {
    background-color: rebeccapurple;
    // margin-right: 100px !important;
    width: 33%;
    @include breakpoint (mobileonly){
        width: 70vw !important;
    }
    @include breakpoint (phablet){
        width: 60vw !important;
    }
    @include breakpoint (tablet){
        width: 45% !important;
    }
    @include breakpoint (laptop){
        width: 35% !important;
    }
}

.wcarousel-nav *.flickity-cell:first-child:nth-last-child(2),
.wcarousel-nav *.flickity-cell:first-child:nth-last-child(2) ~ *.flickity-cell {
    width: 48%;
    @include breakpoint (mobileonly){
        width: 70vw !important;
    }
    @include breakpoint (phablet){
        width: 60vw !important;
    }
    @include breakpoint (tablet){
        width: 50% !important;
    }
    @include breakpoint (laptop){
        width: 50% !important;
    }
}

.wcarousel-nav *.flickity-cell:first-child:nth-last-child(3),
.wcarousel-nav *.flickity-cell:first-child:nth-last-child(3) ~ *.flickity-cell {
    width: 33%;
    @include breakpoint (mobileonly){
        width: 70vw !important;
    }
    @include breakpoint (phablet){
        width: 60vw !important;
    }
    @include breakpoint (tablet){
        width: 45% !important;
    }
    @include breakpoint (laptop){
        width: 40% !important;
    }
}

.wcarousel-nav *.flickity-button {
    min-width: 40px !important;
    width: 40px!important;
    height: 40px !important;
    border-radius: 100% !important;
    background-color: $main-100 !important;
    border: 1px solid $main-902 !important;
    filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2)) !important;
}

.wcarousel-nav *.flickity-prev-next-button.next{
    right: 25px;
}
.wcarousel-nav *.flickity-prev-next-button.previous{
    left: 25px;
}