@import "../../../styles/variables.scss";

.PhoneInput {
    width: 100%;
    background-color: #fff !important;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 0px !important;

    *.PhoneInputCountry{
        width: 80px !important; 
        padding: 10px !important;
        padding-left: 15px;
        padding-right: 15px;
        background-color: $neutral-200!important;
        border: .5px solid $neutral-400;
        border-radius: 5px;
        margin-right: 20px;
  
    }
    &.PhoneInput *.PhoneInputInput {
        margin-left: -10px !important;
        width: 100% !important;
        height: 50px;
        position: relative;
        padding-left: 16px;
        border-radius: 5px;
        outline:1px solid $neutral-800 !important ;
        @include fontNormal(1em, 1em, $neutral-600);

        &:focus {
            border: .5px solid $main-800 ;
            outline:.5px solid $main-800 ;
            background-color: $main-100 ;
            color: $main-901;
            
            -webkit-box-shadow: none;
            box-shadow: none;
        }
        &:disabled {
            cursor: not-allowed;
            color: $neutral-800;
        }
    }
    *.PhoneInputCountryIcon.PhoneInputCountryIcon--border{
        width: 30px !important;
        height: 30px !important;
        overflow: hidden !important;
        display: flex !important;
        align-items: center !important;
        background-color: transparent !important;
        outline: none !important;
        box-shadow: none !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
  
        svg {
            width: 100px !important;
            height: 100px !important;
            margin-top: 5px !important;
            margin-right: 10px !important;
        }
    }
    *select {
       
        min-height: 50px !important;
    }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}
input[type="number"] {
    -moz-appearance: textfield !important; 
}
.select-input{
       
    .select-box {
        width: 100%;
    }
    
   
    .select {
        width: 100% !important;
        outline: none;
        height: fit-content;
        box-sizing: border-box;
        border-radius: 5px;
        margin: 0px;
        position: relative;
        display: grid;
        grid-template-areas: "select";
        align-items: center;
        padding-bottom: 0px;
        // -webkit-appearance:none !important;
        
        
        @include fontNormal(1em, 1em, $gray-600);

        @include breakpoint(mobileonly) {
            width: 100%!important;
            height: fit-content;
        }
        @include breakpoint(tablet) {
            width: 100% !important;
            height: fit-content;
        }

        @include breakpoint(phablet) {
            width: 100% !important; //250PX
        }
        
        &:active{
            // border: .5px solid $main-700;
            background-color: $main-100;
            color: $main-901;
            outline: none;
        }
        &:focus {
            // border: .5px solid $main-700;
            background-color: $main-100;
            color: $main-901;
            outline: none !important;

        }

       
        option:first-child{
            border-top-style: 1px solid $gray-500;
            border-bottom-style: 1px solid $gray-500;
        }
        *:focus {
            outline: none;
        }
        option {
            z-index: 9999 !important;
            float: left;
            @include rowflexx(flex-start);
            display: flex !important;
            @include fontMedium(1em,1em, $main-901);
            background-color: #fff;
            border-bottom-style: 1px solid $gray-500;

            &:hover {
                background-color: $main-100;
                border-bottom-style: 1px solid $main-700;
            }
        }
       
       
    }
}

// radiio input

// $md-radio-checked-color: $vesti-700;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px; 
$md-radio-ripple-size: 15px;

@keyframes ripple {
  
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }
  
  50% { 
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }
  
  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.radiooption{
    height: fit-content;
    padding: 15px 20px;
    border-radius: 8px;
    border: 1px solid $neutral-600;
    background-color: #FCF7F7;
    box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.2);
    margin: 0 !important;
    @include rowflex(flex-start, space-between);

   
    input[type="radio"] {
        display: none;
      
        &:checked + label:before {
            border-color: $main-900;
            animation: ripple 0.2s linear forwards;   
        }
      
        &:checked + label:after {
            transform: scale(1);
        }

        &:checked +label+ div.paymentoption-detail {
            p:nth-child(1){
                color: $main-900;
            }
        }
    }
    label {
        width:fit-content;
        display: inline-block;
        height:$md-radio-size;
        position: relative;
        padding: 0 ($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
      
        &:before, &:after {
            position: absolute;            
            content: '';  
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }
      
        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $md-radio-border-color;
        }
      
        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width:$md-radio-checked-size;
            height:$md-radio-checked-size;
            transform: scale(0);
            background:$main-800;

            
        }
    }
    &.--active {
        border: 1px solid $main-800;
        background-color: $main-100;
        .radiooption__left {
            p:nth-child(1){
                @include fontBold(1.1em, 1em, $main-800);

                @include breakpoint(desktop){
                    @include fontBold(1.3em, 1em, $main-800);
                }
            }
        }
    }
    &__left {
        @include rowflexx(flex-start);
        gap: 10px;
        img {
            width: 35px;
        }
    }
    &__titlesub {
        @include colflex(flex-start, space-between);
        transition: all .5s ease;
        gap: 10px;
        p:nth-child(1){
            margin: 0;
            @include fontMedium(1.1em, 1em, $main-901);

            @include breakpoint(desktop){
                @include fontMedium(1.3em, 1em, $main-901);
            }
        }
        p:nth-child(2){
            margin-bottom: 0px;
            @include fontNormal(.85em,1.2em, $main-901);

            @include breakpoint(desktop){
                @include fontNormal(1em, 1.2em, $main-901);
            }
        }
    }
    
}

// pin input 

.pincode-input-container {
    padding: 0px !important;
    width: 100% !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    @include breakpoint(mobileonly){
        max-width: 300px !important;
        width: fit-content !important;
        display: row !important;
        flex-wrap: wrap !important;
    }
        
    gap: 10px !important;

    .pincode-input-text {
        width: 100% !important;
        height: 70px !important;
        border-radius: 10px !important;
        @include breakpoint(mobileonly){
            margin-top: 10px !important;
        }
    }
    .pincode-input-text:nth-child(4){

        @include breakpoint(mobileonly){
            margin-top: 10px !important;
        }

        @include breakpoint(phablet){
            margin-top: 10px !important;
        }
        
    }

}

// checkbox

.checkbox {
    width: fit-content;
    line-height: 1.1;
    border: none !important;
    @include rowflexx(center);
    padding: 0px;
    @include fontMedium(1em, 1.4em, $neutral-400);
  
    p{
  
        strong {
            color: $main-800;
        }
    }
    
    
    input[type="checkbox"] {
        appearance: none;
        background-color: transparent;
        margin: 0;
        font: inherit;
        color: $main-500;
        width: 1.3em;
        min-width: 1.2em;
        height: 1.2em;
        min-height: 1.3;
        border: 0.15em solid $main-500;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        margin-right: 10px;
        transition: all 0.5s ease;
  
        &:checked {
            border: 0.15em solid $main-800;
        }
        &::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $main-800;
        }
    }
    input[type="checkbox"]:checked::before {
        transform: scale(1);
        
    }
}

.lotus-toggle.react-toggle--checked .react-toggle-track {
    background-color: $main-700;
  }