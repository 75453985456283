@import "../../../styles/variables.scss";


.pagination-container {
    display: flex;
    list-style-type: none;
    margin-top: 50px;
    width: 100%;
    float: right;
    @include rowflex(center,flex-end);

    @include breakpoint(mobileonly){
      width: 100%;
      margin-left: -30px !important;
      float: left;
      // margin: 50px;
      @include rowflex(flex-start,flex-start);
    }
    @include breakpoint(phablet){
        width: 100%;
        margin: 50px;
        @include rowflex(center,center);
    }

    .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: $neutral-800;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;
  
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        color: #fff;
        background-color: $main-800;
        cursor: pointer;
      }
  
      &.selected {
          color: #fff;
        background-color: $main-800;
      }
  
      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid $main-900;
          border-top: 0.12em solid $main-900;
        }
  
        &.left {
          transform: rotate(-135deg) translate(-50%);
        }
  
        &.right {
          transform: rotate(45deg);
        }
      }
  
      &.disabled {
        pointer-events: none;
  
        .arrow::before {
          border-right: 0.12em solid $main-900;
          border-top: 0.12em solid $main-900;
        }
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
  