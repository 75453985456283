@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import './mixins';


body {
  font-size: 16px !important;
}


// Colors
$main-100 : #FFEAE9;
$main-200 : #FFC5C1;
$main-300 : #FFA099;
$main-400 : #FF7B71;
$main-500 : #FF554A;
$main-600 : #EB2C1F;
$main-700 : #C21509;
$main-800 : #990A00;
$main-900 : #700700;
$main-901 : #480500;
$main-902 : #290300;

$neutral-100: #F7F8FA;
$neutral-200: #EAEBF1;
$neutral-300: #DDE1E9;
$neutral-400: #D1D5E0;
$neutral-500: #B6BBC9;
$neutral-600: #858CA0;
$neutral-700: #676D7E;
$neutral-800: #4C5366;
$neutral-900: #2A3147;
$neutral-901: #1B2032;
$neutral-902: #15171B;


$gray-100 : #F9F8F9;
$gray-200 : #F8F6F5;
$gray-300 : #EAEAEA;
$gray-400 : #CCCCCC;
$gray-500 : #8B8C8C;
$gray-600 : #4F4F4F;
$gray-700 : #2D2F30;
$gray-800 : #151617;

$green-100 : #F0FFF6;
$green-200 : #CEFFE3;
$green-300 : #ADFFD0;
$green-400 : #8CFFBD;
$green-500 : #69FCA8;
$green-600 : #4DDA89;
$green-700 : #35B86D;
$green-800 : #219653;
$green-900 : #12743C;
$green-901 : #075227;

$yellow-100 : #FFFAED;
$yellow-200 : #FFF1C6;
$yellow-300 : #FFE79F;
$yellow-400 : #FFDD77;
$yellow-500 : #F2C94C;
$yellow-600 : #D0AA38;
$yellow-700 : #AE8C26;
$yellow-800 : #8C6F18;
$yellow-900 : #6A530D;
$yellow-901 : #483806;


$red-100: #FFF5F5;
$red-200: #FDE7E8;
$red-300: #FFC2C5;
$red-400: #FAA3A7;
$red-500: #F7787E;
$red-600: #EC575E;
$red-700: #D1474E;
$red-800: #C43138;
$red-900: #66191D;
$red-901: #430A0C;
$red-902: #2E0507;


.scrolling  {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $main-300;
}

/* Chrome, Edge, and Safari */
.scrolling::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

.scrolling::-webkit-scrollbar-track {
  // background: $main-300;
  border-radius: 5px;
}

.scrolling::-webkit-scrollbar-thumb {
  background-color: $main-800;
  background-color:-webkit-background-color($main-800);
  border-radius: 14px;
  // border: 3px solid $main-800;
}

// date

.react-datepicker__month-container {
  background-color: $main-100;
  color: $main-902;
  font-size: 1rem;

  .react-datepicker__month {
    padding: 1rem 0;
  }

  .react-datepicker__month-text {
    display: inline-block;
    width: 5rem;
    margin: 0.5rem;
    font-size: 1rem;
    padding: 0.2rem;
    &:hover {
      background-color: $main-800;
    }
  }

  
}

// date
.react-date-picker{
  width: 100% !important;
  border-radius: 5px !important;
  height: 100% !important;
  // height: 60px !important;
  border: 1px solid $neutral-300 !important;
  &--enabled {
    background-color: $main-100 !important;
    border: 1px solid $main-800 !important;
  }
  &--closed {
    background-color: $neutral-100 !important;
    border: 1px solid $neutral-500 !important;
  }
  &__wrapper {
    border: none !important;
  }
}

.react-date-picker__wrapper {
  width: 100% !important;
  height: 100% !important;
}
.react-date-picker__inputGroup{
  width: 100% !important;
  padding-left: 10px;
  padding-right: 10px !important;
  &__input {
    width: fit-content;
    padding-right: 2px;
    outline: none;
    z-index: 9999 !important;
  }
}


.react-date-picker__clear-button {
  display: none !important;
}
.react-date-picker__calendar-button{
  width: 30%;
  // padding: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  // background-color: $main-800 !important;
  background-color: $neutral-200 !important;
  color: #fff !important;
  position: relative;
}
.react-calendar__tile{
  background-color: $main-100 !important;
  &--now {
    border-radius: 5px !important;
    background-color:  $main-700 !important;
    color:#fff !important;
  }
}
.react-calendar{
  background-color: $main-100 !important;
  border-radius: 5px !important;
  margin-top: 5px;
}

.react-calendar__navigation__arrow {
  background-color: $main-800 !important;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 28px !important;
  font-weight: 300 !important;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: $neutral-900 !important;
}

.react-date-picker__button .react-date-picker__button__icon, .react-date-picker__button .react-date-picker__button__icon {
  stroke: $neutral-900 !important;
}